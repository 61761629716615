<p class="badge inline-flex bg-primary-500 py-0.5 text-xs text-white">
    <span [appTooltip]="'translate_kpi_code' | translate">
        {{ kpiPrefix + '-' + kpi.type.code + '-' + kpi.code }}
    </span>

    <button
        class="ms-1 flex items-center rounded-full p-0.5 hover:bg-black hover:bg-opacity-20"
        [appTooltip]="'translate_copy_to_clipboard' | translate"
        (click)="
            copyToClipboard(kpiPrefix + '-' + kpi.type.code + '-' + kpi.code)
        "
    >
        <em class="fa fa-copy text-white"></em>
    </button>
</p>
