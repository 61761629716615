<div class="p-4">
    <!-- Header -->
    <div
        class="-m-4 flex flex-row items-center gap-2 rounded-md bg-primary-600 px-6 pb-16 pt-10"
    >
        <!-- Title -->
        <div class="flex flex-grow flex-col gap-2">
            <h1 [@title]="pageTitle" class="text-2xl text-white">
                {{ pageTitle || '&#8203;' }}
            </h1>
            <h2
                *ngIf="pageSubtitle"
                [@title]="pageSubtitle"
                class="text-gray-400"
            >
                {{ pageSubtitle }}
            </h2>
        </div>

        <!-- Tools -->
        <ng-content select="[tools]"></ng-content>
    </div>

    <!-- Content -->
    <div class="-mt-10 rounded bg-white p-6 shadow">
        <ng-content select="[content]"></ng-content>
    </div>
</div>
