import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'measurementCycle' })
export class MeasurementCyclePipe implements PipeTransform {
    public constructor(private translateService: TranslateService) {}
    public transform(value: number, measurementCycle: string): string {
        const label = this.translateService.instant(
            `translate_${measurementCycle}`
        );

        return `${label} ${value + 1}`;
    }
}
