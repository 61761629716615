import { Component, forwardRef, Input } from '@angular/core';
import { miscFunctions } from 'mnm-webapp';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { uuid } from '@masar/common/utils';

interface File {
    name?: string;
    bytes?: string;
}

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
})
export class FileInputComponent {
    @Input() public isObject: boolean = false;
    @Input() public acceptedMimes: string = '*/*';

    public value: string | File = undefined;
    public readonly id = uuid();
    private onChange?: (value: string | File) => void;

    public writeValue(value: string | File): void {
        this.value = value;
    }

    public registerOnChange(fn: (value: string | File) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {
        // ignored
    }

    public setDisabledState?(): void {
        // ignored
    }

    public loadFile(event: Event): void {
        const fileInput = event.target as HTMLInputElement;
        if (fileInput.files && fileInput.files.length === 1) {
            const file = fileInput.files[0];
            miscFunctions.getBase64(file, (_, bytes) => {
                if (this.isObject) {
                    this.value = { name: file.name, bytes };
                } else {
                    this.value = bytes;
                }
                this.onChange?.(this.value);
            });
        } else {
            this.value = undefined;
            this.onChange?.(this.value);
        }
    }
}
