import {
    CUSTOM_ELEMENTS_SCHEMA,
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslationModule } from '@ng-omar/translation';
import { MnmWebappModule } from 'mnm-webapp';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    AchievementGaugeComponent,
    AlertComponent,
    ContentLoadingComponent,
    DeleteButtonComponent,
    DepartmentLinkComponent,
    DropdownComponent,
    EditButtonComponent,
    FileInputComponent,
    GaugeComponent,
    GoBackButtonComponent,
    HistoryListComponent,
    ImageComponent,
    ListLoadingComponent,
    LoadingComponent,
    LoadingRingComponent,
    MnmFormComponent,
    OperationLinkComponent,
    PermissionLinkComponent,
    PlanLinkComponent,
    ProgressBarComponent,
    ProgressBarInputComponent,
    ProgressRingComponent,
    SearchInputComponent,
    SidebarComponent,
    StrategicGoalLinkComponent,
    TabComponent,
    TableOrderButtonComponent,
    TableOrderColumnComponent,
    TablePaginationComponent,
    TabListComponent,
    UserLinkComponent,
    ValueLoadingComponent,
} from './components';
import {
    ClickOutsideDirective,
    FlatPickrDirective,
    HasAnyPermissionIdDirective,
    HasPermissionIdDirective,
    HighlightDirective,
    IsNotOriginDirective,
    IsOriginDirective,
    ParentsScrollListenerDirective,
    ScrollPositionDirective,
    TooltipDirective,
    WaitUntilLoadedDirective,
    WaitUntilLoadedLoadingComponent,
} from './directives';
import {
    CallFunctionPipe,
    ConcatArraysPipe,
    DaysLeftPipe,
    EnumeratePipe,
    FileSizePipe,
    HasPermissionIdPipe,
    MathAbsPipe,
    MeasurementCyclePipe,
    NestedPropertyPipe,
    PushToArrayPipe,
    RandomizePipe,
    RangeFromToPipe,
    ReverseArrayPipe,
    RoundPipe,
    SafeUrlPipe,
    SearchPipe,
    TakePipe,
    ToDatePipe,
} from './pipes';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ColorPickerModule } from 'ngx-color-picker';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { ExportPdfComponent } from './components/export-pdf/export-pdf.component';
import { FilterPipe } from '@masar/shared/pipes/filter.pipe';
import { ItemsAreUnavailableComponent } from '@masar/shared/components/items-are-unavailable/items-are-unavailable.component';
import { TreeSelectComponent } from './components/tree-select/tree-select.component';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';

const sharedComponents = [
    ImageComponent,
    MnmFormComponent,
    TablePaginationComponent,
    LoadingComponent,
    ListLoadingComponent,
    ProgressRingComponent,
    ContentLoadingComponent,
    ValueLoadingComponent,
    LoadingRingComponent,
    GaugeComponent,
    AchievementGaugeComponent,
    TabListComponent,
    TabComponent,
    PermissionLinkComponent,
    GoBackButtonComponent,
    ProgressBarComponent,
    DeleteButtonComponent,
    EditButtonComponent,
    AlertComponent,
    DropdownComponent,
    TableOrderButtonComponent,
    TableOrderColumnComponent,
    SidebarComponent,
    SearchInputComponent,
    ProgressBarInputComponent,
    OperationLinkComponent,
    UserLinkComponent,
    DepartmentLinkComponent,
    StrategicGoalLinkComponent,
    PlanLinkComponent,
    TreeSelectComponent,
    ComingSoonComponent,
    ExportPdfComponent,
    HistoryListComponent,
    ItemsAreUnavailableComponent,
    FileInputComponent,
];

const sharedDirectives = [
    FlatPickrDirective,
    HasPermissionIdDirective,
    HasAnyPermissionIdDirective,
    WaitUntilLoadedDirective,
    TooltipDirective,
    HighlightDirective,
    ClickOutsideDirective,
    ScrollPositionDirective,
    ParentsScrollListenerDirective,
    IsOriginDirective,
    IsNotOriginDirective,
];

const sharedPipes = [
    SafeUrlPipe,
    EnumeratePipe,
    RandomizePipe,
    TakePipe,
    RoundPipe,
    DaysLeftPipe,
    RangeFromToPipe,
    FileSizePipe,
    ReverseArrayPipe,
    MathAbsPipe,
    ToDatePipe,
    HasPermissionIdPipe,
    NestedPropertyPipe,
    ConcatArraysPipe,
    CallFunctionPipe,
    SearchPipe,
    PushToArrayPipe,
    FilterPipe,
    MeasurementCyclePipe,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslationModule,
        RouterModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        MnmWebappModule,
        SweetAlert2Module,
        ColorPickerModule,
        NzProgressModule,
        NzTreeSelectModule,
    ],
    declarations: [
        // components used by the directives
        WaitUntilLoadedLoadingComponent,

        // components
        sharedComponents,

        // directives
        sharedDirectives,

        // pipes
        sharedPipes,

        CallFunctionPipe,
    ],
    exports: [
        // components
        sharedComponents,

        // directives
        sharedDirectives,

        // pipes
        sharedPipes,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return { ngModule: SharedModule, providers: [] };
    }
}
