import { Validators } from '@angular/forms';
import { CustomValidators } from 'mnm-webapp';
import { MnmFormField } from '@masar/shared/components';
import { AppSettingFetcherService } from '@masar/core/services';

export const fields: (
    appSettingFetcherService: AppSettingFetcherService
) => MnmFormField[] = appSettingFetcherService => [
    {
        name: 'id',
        hide: true,
    },

    {
        name: 'scopes',
        hide: true,
    },

    {
        name: 'nameAr',
        label: 'translate_name_in_arabic',
        type: 'text',
        validators: [Validators.required],
    },

    {
        name: 'nameEn',
        label: 'translate_name_in_english',
        type: 'text',
    },

    {
        name: 'tags',
        type: 'select',
        label: 'translate_tags',
        bindLabel: 'name',
        multiple: true,
        canAddTags: true,
        hideSelected: true,
        minTermLength: 2,
    },

    {
        name: 'link',
        label: 'translate_file_link',
        type: 'text',
    },

    {
        name: 'file',
        label: 'translate_file',
        type: 'file',
        note: 'translate_max_file_size_is_150_mb',
        mime: appSettingFetcherService.getAllowedFileTypes,
        validators: [CustomValidators.maxFileSize(150 * 1024 * 1024, '150MB')],
    },
];
