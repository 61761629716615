import { ServiceMiscItem } from '@masar/common/types';

export const serviceMiscMapper = (item: ServiceMiscItem): ServiceMiscItem => {
    const names = [
        item.supplementaryServiceName,
        item.subServiceName,
        item.mainServiceName,
    ].filter(Boolean);

    return { ...item, name: names.shift(), nameDescription: names.join(' - ') };
};
