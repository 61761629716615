import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    ViewChild,
} from '@angular/core';
import { Gauge } from 'gaugeJS';

@Component({
    selector: 'app-gauge',
    templateUrl: 'gauge.component.html',
})
export class GaugeComponent implements AfterViewInit, OnChanges {
    @ViewChild('target') public targetRef: ElementRef<HTMLCanvasElement>;
    @ViewChild('textTarget') public textTargetRef: ElementRef<HTMLDivElement>;

    @Input() public value: number = 0;
    @Input() public radius: number = 150;
    @Input() public labels: number[] = [];
    @Input() public zones: { strokeStyle: string; min: number; max: number }[];
    @Input() public appearance: 'light' | 'dark' = 'dark';
    @Input() public tickColor = '#333333';
    @Input() public subTickColor = '#666666';
    @Input() public maxValue = 140;
    @Input() public style: 'default' | 'style_1' = 'default';

    public ngAfterViewInit(): void {
        this.update();
    }

    public ngOnChanges(): void {
        this.update();
    }

    private update(): any {
        if (!this.targetRef) return;

        const target = this.targetRef.nativeElement;
        const opts = this.getOptions();

        const demoGauge = new Gauge(target);
        demoGauge.setTextField(this.textTargetRef.nativeElement);

        demoGauge.setOptions(opts);
        demoGauge.minValue = 0;
        demoGauge.maxValue = this.maxValue;
        demoGauge.set(this.value);
    }

    private getOptions(): any {
        switch (this.style) {
            case 'style_1':
                return {
                    angle: -0.4,
                    lineWidth: 0.06,
                    radiusScale: 0.9,
                    fontSize: 22,
                    pointer: {
                        length: 0.3,
                        strokeWidth: 0.026,
                        color:
                            this.appearance === 'dark' ? '#000000' : '#FFFFFF',
                    },
                    limitMax: false,
                    limitMin: false,
                    generateGradient: true,
                    highDpiSupport: true,
                    staticZones: this.zones,

                    staticLabels: {
                        font: `${0.04 * this.radius + 4}px sans-serif`,
                        color:
                            this.appearance === 'dark' ? '#000000' : '#FFFFFF',
                        labels: this.labels,
                        fractionDigits: 0,
                    },

                    renderTicks: {
                        divisions: 11,
                        divWidth: 0.9,
                        divLength: 0.4,
                        divColor: this.tickColor,
                        subDivisions: 3,
                        subLength: 0.24,
                        subWidth: 0.6,
                        subColor: this.subTickColor,
                    },
                };

            default:
                return {
                    angle: 0.07,
                    lineWidth: 0.2,
                    radiusScale: 0.9,
                    pointer: {
                        length: 0.6,
                        strokeWidth: 0.022,
                        color:
                            this.appearance === 'dark' ? '#000000' : '#FFFFFF',
                    },
                    staticLabels: {
                        font: `${0.04 * this.radius + 4}px sans-serif`,
                        color:
                            this.appearance === 'dark' ? '#000000' : '#FFFFFF',
                        labels: this.labels,
                        fractionDigits: 0,
                    },
                    staticZones: this.zones,
                    limitMax: false,
                    limitMin: false,
                    highDpiSupport: true,
                    renderTicks: {
                        divisions: 11,
                        divWidth: 0.9,
                        divLength: 0.4,
                        divColor: this.tickColor,
                        subDivisions: 3,
                        subLength: 0.24,
                        subWidth: 0.6,
                        subColor: this.subTickColor,
                    },
                };
        }
    }
}
