import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// cspell:ignore ايام البدء متبقي الانتهاء منتهية
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const status = (days: number, lang: string) => {
    const isEnglish = lang === 'en';
    const labels = { soon: '', running: '', expired: '' };

    if (isEnglish) {
        const daysString = days > 1 ? 'days' : 'day';
        labels.soon = `${days} ${daysString} left to start`;
        labels.running = `${days} ${daysString} to end`;
        labels.expired = `Expired ${days} ${daysString} ago`;
    } else {
        const daysString = days === 1 ? 'يوم' : days <= 10 ? 'ايام' : 'يوم';
        labels.soon = `متبقي ${days} ${daysString} على البدء`;
        labels.running = `متبقي ${days} ${daysString} على تاريخ انتهاء الطلب`;
        labels.expired = `منتهية`;
    }

    return {
        soon: { label: labels.soon, class: 'text-gray-500' },
        running: { label: labels.running, class: 'text-green-500' },
        expired: { label: labels.expired, class: 'text-red-500' },
    };
};

/**
 * @name DaysLeft
 * @description It Takes time in milliseconds and returns the
 * smallest integer greater than or equal to a number of days
 * @param {number} timeMs - The time in milliseconds.
 */
const daysLeft = (timeMs: number): number =>
    Math.ceil(timeMs / (1000 * 60 * 60 * 24));

@Pipe({ name: 'daysLeft' })
export class DaysLeftPipe implements PipeTransform {
    public constructor(private translateService: TranslateService) {}

    /**
     * It takes a start date and an end date and returns a string that describes the status of the
     * event
     * @param {Date} startTime - The start time of the event.
     * @param {Date} endTime - Date = new Date()
     * @param {string} [lang=ar] - string = 'ar'
     * @returns A string
     */
    public transform(
        startTime: Date,
        endTime: Date = new Date()
    ): { label: string; class: string } {
        startTime = new Date(startTime);
        endTime = new Date(endTime);
        const lang = this.translateService.currentLang;
        const now = new Date();
        // now.setHours(0, 0, 0, 0);

        // Checking if the start time is greater than the current time. If it is,
        // it will return the number of days left to start.
        if (startTime.getTime() >= now.getTime()) {
            const diffTime = startTime.getTime() - now.getTime();
            return status(daysLeft(diffTime), lang).soon;
        }
        // Checking if the current time is greater than the current time. If it is,
        // it will return the number of expired days.
        else if (now.getTime() >= endTime.getTime()) {
            const diffTime = now.getTime() - endTime.getTime();
            return status(daysLeft(diffTime + 1), lang).expired;
        }
        // else it will return the number of days left
        else {
            const diffTime = endTime.getTime() - now.getTime();
            return status(daysLeft(diffTime), lang).running;
        }
    }
}
