<div class="custom-file">
    <input
        id="{{ id }}"
        type="file"
        class="custom-file-input"
        accept="{{ acceptedMimes }}"
        (change)="loadFile($event)"
    />
    <label class="custom-file-label" for="{{ id }}">
        <ng-container *ngIf="isObject; else fileLabelTemplate">
            {{
                value ? $any(value).name : ('translate_select_file' | translate)
            }}
        </ng-container>

        <ng-template #fileLabelTemplate>
            {{
                (value
                    ? 'translate_file_has_been_selected'
                    : 'translate_select_file'
                ) | translate
            }}
        </ng-template>
    </label>
</div>
