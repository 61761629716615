import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { miscFunctions } from 'mnm-webapp';
import { MnmFormState } from './mnm-form.state';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { MnmFormField } from './mnm-form-field.model';
import { functions } from '@masar/common/misc/functions';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'mnm-form',
    templateUrl: './mnm-form.component.html',
})
export class MnmFormComponent implements OnInit {
    @Input() public state: MnmFormState;
    @Input() public translateLabels: boolean = false;
    @Input() public validationMessages: any;

    private defaultValidationMessages: any = {
        required: 'translate_this_field_is_mandatory',
        // cspell:disable-next
        maxlength:
            'translate_this_field_should_not_have_more_than_required_length_characters',
        // cspell:disable-next
        minlength:
            'translate_this_field_should_have_at_least_required_length_characters',
        match: 'translate_passwords_do_not_match',
        email: 'translate_invalid_email_address',
        phone: 'translate_invalid_phone_number',
        // cspell:disable-next
        maxfilesize:
            'translate_the_file_should_not_exceed_max_file_size_display',
        min: 'translate_value_cannot_be_less_than_min',
        max: 'translate_value_cannot_be_larger_than_max',
        noWhitespace: 'translate_value_cannot_be_space_only',
    };

    public constructor(private translateService: TranslateService) {}

    public ngOnInit(): void {
        this.validationMessages = {
            ...this.defaultValidationMessages,
            ...this.validationMessages,
        };
    }

    public isRequired(field: MnmFormField): boolean {
        const control = this.state.group.get(field.name);
        return functions.hasRequiredField(control);
    }

    public loadFile(
        event: Event,
        control: AbstractControl,
        field: MnmFormField
    ): void {
        const fileInput = event.target as HTMLInputElement;
        if (fileInput.files && fileInput.files.length === 1) {
            const file = fileInput.files[0];
            miscFunctions.getBase64(file, (_, bytes) => {
                if (field.isFileObject) {
                    control.setValue({ name: file.name, bytes });
                } else {
                    control.setValue(bytes);
                }

                control.markAsDirty();
                control.markAsTouched();
            });
        } else {
            control.setValue(null);
            control.markAsDirty();
            control.markAsTouched();
        }
    }

    public getErrorMessage(fieldName: string): Observable<string> {
        const errorKey = Object.keys(
            this.state.group.controls[fieldName].errors
        )[0];
        const errorStr = this.validationMessages[errorKey];
        const errorObject =
            this.state.group.controls[fieldName].errors[errorKey];

        if (this.translateLabels) {
            return this.translateService.get(errorStr, errorObject);
        } else {
            return of(errorStr);
        }
    }

    public asField(field: MnmFormField): MnmFormField {
        return field;
    }

    public defaultCompareWith(a: any, b: any): boolean {
        return a.id && b.id ? a.id === b.id : a === b;
    }
}
