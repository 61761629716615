<button
    class="btn btn-primary relative"
    [appTooltip]="title | translate"
    [disabled]="isDisabled"
    (click)="onClick()"
    type="button"
>
    <i
        class="fa-solid"
        [class.text-green-400]="isDone !== undefined && isDone"
        [class.text-yellow-400]="isDone !== undefined && !isDone"
        [ngClass]="icon"
    ></i>
    <p
        *ngIf="progress !== undefined && progress !== null"
        class="absolute bottom-full left-1/2 -translate-x-1/2 translate-y-1/2 transform whitespace-nowrap rounded-md bg-green-500 px-1 py-0.5 text-xs font-bold shadow"
    >
        {{ progress }}%
    </p>
    <p
        *ngIf="count !== undefined && count !== null && count > 0"
        class="absolute -end-1.5 -top-1.5 flex h-4 w-4 items-center justify-center whitespace-nowrap rounded-full bg-green-500 text-xs font-bold shadow"
    >
        {{ count }}
    </p>
</button>
