<app-list-loading [items]="item?.values">
    <!-- Category name & parameter -->
    <div class="mb-5 flex flex-row items-center gap-2">
        <!-- Category name -->
        <div class="rounded bg-gray-100 px-3 py-2">
            {{ item?.category.name }}
        </div>

        <!-- Parameter -->
        <div class="rounded bg-gray-100 px-3 py-2">
            {{ item?.parameter.toUpperCase() }}
        </div>

        <input
            class="flex-grow"
            [placeholder]="'translate_search_by_name' | translate"
            [(ngModel)]="searchText"
            aria-label="Search"
        />
    </div>

    <div class="mb-5 flex flex-row items-center justify-center gap-2">
        <!-- Aggregated value -->
        <div
            class="flex flex-col items-center justify-center gap-1 rounded border px-3 py-2"
            [ngClass]="{
                'border-red-800 bg-red-100 text-red-800':
                    aggregatedValue !== null &&
                    actualValue !== null &&
                    aggregatedValue !== actualValue,
                'border-green-800 bg-green-100 text-green-800':
                    aggregatedValue !== null &&
                    actualValue !== null &&
                    aggregatedValue === actualValue,
                'border-gray-800 bg-gray-100':
                    aggregatedValue === null || actualValue === null
            }"
        >
            <span>{{ 'translate_aggregated_value' | translate }}</span>
            <span class="text-xl font-bold">{{
                aggregatedValue ?? 'N/A'
            }}</span>
        </div>

        <!-- Actual value -->
        <div
            class="flex flex-col items-center justify-center gap-1 rounded border border-gray-800 bg-gray-100 px-3 py-2"
        >
            <span>{{ 'translate_actual_value' | translate }}</span>
            <span class="text-xl font-bold">{{ actualValue ?? 'N/A' }}</span>
        </div>
    </div>

    <!-- Items -->
    <div class="mb-5 grid grid-cols-2 items-center gap-2">
        <ng-container
            *ngFor="
                let value of item?.values
                    | filter : searchText : 'subcategory.name';
                let idx = index
            "
        >
            <span>{{ value.subcategory.name }}</span>
            <input
                (keyup)="recalculateAggregatedValue()"
                type="number"
                [(ngModel)]="value.value"
            />
        </ng-container>
    </div>

    <!-- Submit button -->
    <div class="text-center">
        <button
            class="btn btn-primary inline-flex items-center gap-2"
            (click)="submit()"
            [disabled]="isSubmitting"
        >
            <app-loading-ring
                *ngIf="isSubmitting"
                class="me-2"
            ></app-loading-ring>
            <i class="fa-light fa-save me-2"></i>
            <span>{{ 'translate_save' | translate }}</span>
        </button>
    </div>
</app-list-loading>
