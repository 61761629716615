export const permissionList = {
    // Full access
    fullAccess: 'full_access',

    // Kpi
    kpi: 'kpi',
    kpiRead: 'kpi:read',
    kpiWrite: 'kpi:write',
    kpiDelete: 'kpi:delete',

    // KpiResult
    kpiResult: 'kpi_result',
    kpiResultRead: 'kpi_result:read',
    kpiResultEntry: 'kpi_result:entry',
    kpiResultFlow: 'kpi_result:flow',
    kpiResultWrite: 'kpi_result:write',
    kpiResultDelete: 'kpi_result:delete',
    kpiResultApprove: 'kpi_result:approve',
    kpiResultDynamic: 'kpi_result:dynamic',
    kpiResultDynamicApprove: 'kpi_result:dynamic_approve',
    kpiResultPeriodEvaluate: 'kpi_result:period_evaluate',
    kpiResultPeriodExportEvaluation: 'kpi_result:period_export_evaluation',
    kpiResultDirectApprove: 'kpi_result:direct_approve',
    kpiResultResponseApprovalTransfer: 'kpi_result:response_approval_transfer',

    // Operation
    operation: 'operation',
    operationRead: 'operation:read',
    operationWrite: 'operation:write',
    operationDelete: 'operation:delete',

    // Plan
    plan: 'plan',
    planRead: 'plan:read',
    planWrite: 'plan:write',
    planDelete: 'plan:delete',
    planIntermediateInitialApproval: 'plan:intermediate_initial_approval',
    planImmediateIntermediateInitialApproval:
        'plan:immediate_intermediate_initial_approval',
    planInitialApproval: 'plan:initial_approval',
    planSubsubtaskFinalApproval: 'plan:subsubtask_final_approval',

    // Benchmark
    benchmark: 'benchmark',
    benchmarkRead: 'benchmark:read',
    benchmarkWrite: 'benchmark:write',
    benchmarkDelete: 'benchmark:delete',

    // User
    user: 'user',
    userRead: 'user:read',
    userWrite: 'user:write',
    userDelete: 'user:delete',

    // Department
    department: 'department',
    departmentRead: 'department:read',
    departmentWrite: 'department:write',
    departmentDelete: 'department:delete',

    // Report
    reportDepartment: 'report:department',
    reportKpi: 'report:kpi',

    // Partner
    partner: 'partner',
    partnerExportEvaluation: 'partner:export_evaluation',

    // Partnership
    partnership: 'partnership',
    partnershipRead: 'partnership:read',
    partnershipWrite: 'partnership:write',
    partnershipDelete: 'partnership:delete',
    partnershipReviewer: 'partnership:reviewer',

    // Service
    service: 'service',

    // Improved Opportunity
    improvementOpportunity: 'improvement_opportunity',

    // success factor
    successFactor: 'success_factor',

    // innovation
    innovation: 'innovation',
    innovator: 'innovator',

    // statistical report
    statisticalReport: 'statistical_report',
    statisticalReportDataEntry: 'statistical_report:data_entry',
    statisticalReportRead: 'statistical_report:read',
    statisticalReportWrite: 'statistical_report:write',
    statisticalReportDelete: 'statistical_report:delete',
    statisticalReportLockAndUnlock: 'statistical_report:lock_and_unlock',
    statisticalReportPublish: 'statistical_report:publish',
    statisticalReportExport: 'statistical_report:export',

    // tournament
    tournament: 'tournament',
    tournamentRead: 'tournament:read',

    // risk
    risk: 'risk',
    riskRead: 'risk:read',
    riskWrite: 'risk:write',
    riskDelete: 'risk:delete',

    // Other
    kpiResultTargetSettingMethod: 'kpi_result_target_setting_method',
    kpiResultCapabilityType: 'kpi_result_capability_type',
    kpiTag: 'kpi_tag',
    kpiType: 'kpi_type',
    kpiBalancedBehaviorCard: 'kpi_balanced_behavior_card',
    policy: 'policy',
    operationRuleAndRegulation: 'operation_rule_and_regulation',
    improvementOpportunityInputSource: 'improvement_opportunity_input_source',
    improvementOpportunityInputCategory:
        'improvement_opportunity_input_category',
    serviceCategory: 'service_category',
    operationSpecification: 'operation_specification',
    capabilityType: 'capability_type',
    capability: 'capability',
    operationEnhancementType: 'operation_enhancement_type',
    planInput: 'plan_input',
    team: 'team',
    notification: 'notification',
    strategicGoal: 'strategic_goal',
    governmentStrategicGoal: 'government_strategic_goal',
    planCategory: 'plan_category',
    kpiResultCategory: 'kpi_result_category',
} as const;
