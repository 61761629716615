import {
    Component,
    EventEmitter,
    Input,
    NgModuleRef,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ModalService } from 'mnm-webapp';
import { functions } from '@masar/common/misc/functions';
import { permissionList } from '@masar/common/constants';
import {
    Department,
    Kpi,
    KpiResult,
    KpiResultPeriod,
} from '@masar/common/models';
import { PeriodCapabilityDialogComponent } from '@masar/pages/kpi/detail/components/period-capability-dialog/period-capability-dialog.component';
import { KpiResultAttachmentListComponent } from '@masar/pages/kpi/helpers';
import { KpiResultPeriodsBreakdownDialogComponent } from '@masar/pages/kpi/detail/components/kpi-result-periods-breakdown-dialog/kpi-result-periods-breakdown-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { EvaluateService } from '@masar/features/evaluate/services/evaluate.service';
import { OrganizationOrigin } from '@masar/common/enums';
import { ApprovalComponent } from '@masar/pages/kpi/update-result/components';
import { Subject } from 'rxjs';

const viewModes = ['all', 'by_year', 'by_department'] as const;

@Component({
    selector: 'app-kpi-results',
    templateUrl: './kpi-results.component.html',
})
export class KpiResultsComponent {
    @Input()
    public set kpi(kpi: Kpi) {
        this.kpiValue = kpi;
        this.setKpiTablesValue();
    }

    public get kpi(): Kpi {
        return this.kpiValue;
    }

    @ViewChild('periodButtonTemplate')
    public periodButtonTemplate: TemplateRef<any>;

    @Output()
    public beforeNavigate = new EventEmitter<void>();

    @Output() public update = new EventEmitter<void>();

    public readonly organizationOrigin = OrganizationOrigin;

    public readonly permissionList = permissionList;

    public currentYear = new Date().getFullYear();

    public resultViewModeList = viewModes;

    public resultViewMode: (typeof viewModes)[number] = viewModes[0];

    public resultsGroupedByDepartments: {
        department: Department;
        results: KpiResult[];
    }[];

    public resultsGroupedByYear: {
        year: number;
        results: KpiResult[];
    }[];

    public shownResultIds: Record<string, boolean> = {};

    private kpiValue?: Kpi;

    public constructor(
        private readonly modalService: ModalService,
        private readonly moduleRef: NgModuleRef<any>,
        private readonly translateService: TranslateService,
        private readonly evaluateService: EvaluateService
    ) {}

    public showResultAttachments(kpiResult: KpiResult): void {
        // FIXME: Move this to outside kpi module
        this.modalService.show(KpiResultAttachmentListComponent, {
            beforeInit: c => {
                c.type = 'kpi-result';
                c.targetId = kpiResult.id;
                c.mode = 'view';
            },
            moduleRef: this.moduleRef,
        });
    }

    public showResultCapabilities(kpiResult: KpiResult): void {
        // FIXME: Move this to outside kpi module
        this.modalService.show(PeriodCapabilityDialogComponent, {
            beforeInit: c => {
                c.periods = kpiResult.periods;
                c.measurementCycle = kpiResult.measurementCycle;
            },
            moduleRef: this.moduleRef,
        });
    }

    public showPeriodsBreakdownDialog(kpiResult: KpiResult): void {
        this.modalService
            .show(KpiResultPeriodsBreakdownDialogComponent, {
                title: this.translateService.instant(
                    'translate_period_breakdown'
                ),
                beforeInit: c => {
                    c.periods = kpiResult.periods;
                    c.measurementCycle = kpiResult.measurementCycle;
                },
                moduleRef: this.moduleRef,
            })
            .then();
    }

    public showEvaluationPopup(item: KpiResultPeriod, year: number): void {
        const evaluateString =
            this.translateService.instant('translate_evaluate');

        const periodString = this.translateService.instant(
            'translate_period_period',
            { period: item.period + 1 }
        );

        const title = `${evaluateString} ${this.kpi.name} - ${year} - ${periodString}`;

        this.evaluateService
            .showEvaluationsList(
                item,
                'kpi-result-period',
                title,
                this.kpi.id,
                { name: '', template: this.periodButtonTemplate, item: item }
            )
            .then(obs => obs.subscribe(() => this.update.emit()));
    }

    public async showApprovalDialog(period: KpiResultPeriod): Promise<void> {
        const subject = new Subject();

        const component = await this.modalService.show(ApprovalComponent, {
            title: this.translateService.instant(
                'translate_approval_and_suggestions'
            ),
            beforeInit: c => {
                c.period = period;
            },
            onDismiss: () => {
                subject.complete();
                this.modalService.dismiss(component);
            },
            moduleRef: this.moduleRef,
        });
    }

    private setKpiTablesValue(): void {
        if (!this.kpi) return;

        // Figure out the results grouped by:

        // 1. Departments
        this.resultsGroupedByDepartments = functions
            .groupBy(
                this.kpi.results,
                x => x.department,
                (a, b) => a.id === b.id
            )
            .map(x => ({
                department: x.key,
                results: x.items,
            }));

        // 2. Years
        this.resultsGroupedByYear = functions
            .groupBy(this.kpi.results, x => x.year)
            .map(x => ({
                year: x.key,
                results: x.items,
            }));
    }
}
