import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { mnmHttpInterceptorParams, Result } from 'mnm-webapp';
import { environment } from '@masar/env/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { AppSetting, ThemeSetting } from '@masar/common/models';
import { SignalrService } from './signalr.service';
import { messageTypeList } from '@masar/common/misc/message-type-list';

@Injectable()
export class AppSettingFetcherService implements OnDestroy {
    private appSettings$ = new BehaviorSubject<AppSetting>(null);
    private unsubscribeAll = new Subject();

    public constructor(
        private httpClient: HttpClient,
        signalrService: SignalrService
    ) {
        this.reload();

        signalrService
            .messages()
            .pipe(takeUntil(this.unsubscribeAll))
            .pipe(filter(x => x.type === messageTypeList.appSettingUpdate))
            .subscribe(() => {
                this.reload();
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.appSettings$.complete();
    }

    public get get$(): Observable<AppSetting> {
        return this.appSettings$.pipe(filter(x => x !== null));
    }

    public get get(): Observable<AppSetting> {
        return this.get$.pipe(first());
    }

    public get snapshot(): AppSetting {
        return this.appSettings$.value;
    }

    public get getAllowedFileTypes(): string {
        return this.appSettings$.value.allowedFileTypes?.join(',') || '';
    }

    public appLogo(): Observable<Blob> {
        return this.httpClient.get(environment.apiUrl + '/setting/logo', {
            responseType: 'blob',
            params: new HttpParams().append(
                mnmHttpInterceptorParams.stealth,
                'true'
            ),
        });
    }

    public appLoginLogo(): Observable<Blob> {
        return this.httpClient.get(environment.apiUrl + '/setting/login-logo', {
            responseType: 'blob',
            params: new HttpParams().append(
                mnmHttpInterceptorParams.stealth,
                'true'
            ),
        });
    }

    public appLoginBackground(): Observable<Blob> {
        return this.httpClient.get(
            environment.apiUrl + '/setting/login-background',
            {
                responseType: 'blob',
                params: new HttpParams().append(
                    mnmHttpInterceptorParams.stealth,
                    'true'
                ),
            }
        );
    }

    public appTheme(sustain: boolean = false): Observable<ThemeSetting> {
        return this.httpClient
            .get<Result<ThemeSetting>>(`${environment.apiUrl}/setting/theme`, {
                params: new HttpParams().append(
                    mnmHttpInterceptorParams.sustainOnNavigation,
                    sustain
                ),
            })
            .pipe(map(res => res.extra));
    }

    private reload(): void {
        this.httpClient
            .get<Result<AppSetting>>(`${environment.apiUrl}/setting`, {
                params: new HttpParams()
                    .append(mnmHttpInterceptorParams.sustainOnNavigation, true)
                    .append(mnmHttpInterceptorParams.stealth, true),
            })
            .pipe(
                map(res => {
                    this.appSettings$.next(res.extra);
                })
            )
            .subscribe();
    }
}
