<ng-container [ngSwitch]="style">
    <!-- Style 1 -->
    <div *ngSwitchCase="'style_1'" class="relative">
        <canvas
            [width]="radius"
            [height]="radius + -0.1 * radius"
            #target
        ></canvas>

        <div
            #textTarget
            [ngClass]="{
                'text-white': appearance === 'light',
                'text-black': appearance === 'dark'
            }"
            [ngStyle]="{
                fontSize: 0.08 * radius + 'pt',
                marginTop: 0.05 * radius + 'px'
            }"
            class="absolute bottom-5 left-1/2 -translate-x-1/2 font-bold"
        ></div>
    </div>

    <!-- Default -->
    <div *ngSwitchDefault class="relative">
        <div
            #textTarget
            [ngClass]="{
                'text-white': appearance === 'light',
                'text-black': appearance === 'dark'
            }"
            [ngStyle]="{
                fontSize: 0.08 * radius + 'pt',
                marginTop: 0.05 * radius + 'px'
            }"
            class="absolute left-1/2 top-1/2 mx-auto inline-block -translate-x-1/2 -translate-y-1/2 transform font-bold"
        ></div>
        <canvas
            [width]="radius"
            [height]="radius + -0.42 * radius"
            #target
        ></canvas>
    </div>
</ng-container>
