import { Component, Input } from '@angular/core';
import { Department, Team, User } from '@masar/common/models';

@Component({
    selector: 'app-assigned-entity',
    template: `
        <ng-container *ngIf="item">
            <!-- Assigned Departments -->
            <ng-container *ngIf="item.assignedDepartment">
                <ng-container
                    *ngIf="
                        isArray(item.assignedDepartment);
                        else singleDepartment
                    "
                >
                    <div *ngFor="let department of item.assignedDepartment">
                        <app-department-link
                            [item]="department"
                        ></app-department-link>
                    </div>
                </ng-container>
                <ng-template #singleDepartment>
                    <app-department-link
                        [item]="item.assignedDepartment"
                    ></app-department-link>
                </ng-template>
            </ng-container>

            <!-- Assigned Teams -->
            <ng-container *ngIf="item.assignedTeam">
                <ng-container
                    *ngIf="isArray(item.assignedTeam); else singleTeam"
                >
                    <div *ngFor="let team of item.assignedTeam">
                        <a [routerLink]="['', 'team', 'detail', team.id]">
                            {{ team.name }}
                        </a>
                    </div>
                </ng-container>
                <ng-template #singleTeam>
                    <a
                        [routerLink]="[
                            '',
                            'team',
                            'detail',
                            item.assignedTeam['id']
                        ]"
                    >
                        {{ item.assignedTeam['name'] }}
                    </a>
                </ng-template>
            </ng-container>

            <!-- Assigned Users -->
            <ng-container *ngIf="item.assignedUser">
                <ng-container
                    *ngIf="isArray(item.assignedUser); else singleUser"
                >
                    <div *ngFor="let user of item.assignedUser">
                        <span>{{ user.name }}</span>
                    </div>
                </ng-container>
                <ng-template #singleUser>
                    <span>{{ item.assignedUser['name'] }}</span>
                </ng-template>
            </ng-container>
        </ng-container>
    `,
})
export class AssignedEntityComponent {
    @Input() public item: {
        assignedDepartment: Department | Department[];
        assignedTeam: Team | Team[];
        assignedUser: User | User[];
    };

    public isArray(value: any): boolean {
        return Array.isArray(value);
    }
}
