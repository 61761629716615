import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionService } from '../services/permission.service';
import { tap } from 'rxjs/operators';
import { NotificationService } from 'mnm-webapp';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
    public constructor(
        private readonly permissionService: PermissionService,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly translateService: TranslateService
    ) {}

    public canActivate(
        activatedRoute: ActivatedRouteSnapshot,
        _: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        return this.canActivateHandler(activatedRoute);
    }

    public canActivateChild(
        activatedRoute: ActivatedRouteSnapshot,
        _: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivateHandler(activatedRoute);
    }

    private canActivateHandler(
        activatedRoute: ActivatedRouteSnapshot
    ): Observable<boolean> | boolean {
        const { permissionId } = activatedRoute.data;

        if (!permissionId) return true;

        return this.permissionService
            .ensureUserHasPermission(permissionId)
            .pipe(
                tap(hasPermission => {
                    if (!hasPermission) {
                        this.router.navigate(['']).then();

                        this.translateService
                            .get(
                                'translate_you_do_not_have_the_permission_to_access_this_page'
                            )
                            .subscribe(str => {
                                this.notificationService.notifyError(str);
                            });
                    }
                })
            );
    }
}
